<template>
  <div >
    <div >
      <span>创建时间：</span>
      <el-date-picker
        class="ml32"
        v-model="timeArr"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        value-format="timestamp"
      />
      <el-button type="primary" class="ml32" ref="search" @click="warnStatus">搜索</el-button>
    </div>
    <div class="plt countBox">
      <h6>设备数量</h6>
      <el-row>
        <el-col :span="8" class="count">
          <div>
            <h6>电池</h6>
            <div class="middle_total__box">
              <div class="middle_left__box middleBox plt">
                <div class="borderRight">{{list.totalNum}}</div>
                <p>总数</p>
              </div>
              <div class="middle_center__box middleBox plt">
                <div class="">
                  <el-progress
                    type="circle"
                    :percentage="abnormalNum"
                    color="red"
                    :width="widthNum"
                  ></el-progress>
                </div>
                <p>异常</p>
              </div>
              <div class="middle_right__box middleBox plt">
                <div class="">
                  <el-progress
                    class="progress"
                    type="circle"
                    :percentage="normalNum"
                    :width="widthNum"
                  />
                </div>
                <p>正常</p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
       <h6 class="ml32 mb10">离线报警次数</h6>
     <div class="warnBox ml32">
       <div class="plt">
         <h6>电池</h6>
         <div class="warnBox_warnInnerBox">
           <h6>{{list.alarmNum}}</h6>
           <p>离线报警次数</p>
         </div>

       </div>
     </div>
  </div>
</template>

<script>
import {warnStatus} from "@/api/dataStatement";
export default {
  data() {
    return {
      widthNum:100,
      list:{
        abnormalNum:0,
        totalNum:0,
        normalNum:0
      },
      timeArr: [],
      form:{
        startTime:'',
        endTime:''
      }
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted(){
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.warnStatus()
      }
    };
    // this.getList();
    this.warnStatus();
  },
  computed:{
    abnormalNum(){//异常
      return (this.list.abnormalNum / this.list.totalNum) * 100;
    },
    normalNum(){//正常
      return (this.list.normalNum / this.list.totalNum) * 100;
    },
  },
  methods: {
    // 获取数据
    async warnStatus(){
      if(this.timeArr && this.timeArr.length !== 0){
        this.form.startTime = this.timeArr[0];
        this.form.endTime = this.timeArr[1];
      }else{
        this.form.startTime = new Date().getTime() - 7000*60*60*24;
        this.form.endTime = new Date().getTime();
        this.timeArr = this.timeArr || []
        this.timeArr.push(this.form.startTime,this.form.endTime)
      }
      // this.form.haiLiDeviceNo = this.$route.query.haiLiDeviceNo;
      const params = {...this.form}
       try{
          const res = await warnStatus(params);
          this.list = res.mdata.alarmStatistics;
          let that = this;
          this.$nextTick(()=>{
            const abnormal = document.getElementsByClassName('el-progress__text')[0]
            const normal = document.getElementsByClassName('el-progress__text')[1]
            normal.innerText = that.list.normalNum;
            abnormal.innerText = that.list.abnormalNum;
            normal.style.fontSize = '30px';
            abnormal.style.fontSize = '30px';
            abnormal.style.color = "red";
            normal.style.color = "rgb(14, 114, 245)";
          })

       }catch(e){
         console.log(e);
       }
    },
  },
  components: {},
};
</script>

<style scoped lang='less'>

.count {
  margin-bottom: 16px;
  padding: 16px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 1px 1px 6px 0 rgba(136, 156, 176, 0.1);
  .middle_total__box {
    display: flex;

    .middleBox {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      // background: pink;
      padding: 10px;
      width: 200px;
      height: 200px;
      .el-progress__text{
        font-size: 20px !important;
      }


      .borderRight {
        border-right: 1px solid #eee;
        width: 130px;
        height: 30px;
        margin: 35px;
        font-size: 30px;
        text-align: center;
      }
    }
  }
}
.warnBox{
  width: 200px;
  height: 200px;
  border: 1px solid #eee;
  border-radius: 10px;

  .warnBox_warnInnerBox{
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    h6{
      font-size:25px;
    }
  }
}
</style>
